import { Component, Vue } from 'vue-property-decorator';
import { BASE_API_URL } from '../../utils/Constants';
import { queryParams } from '../../utils/Typography';

@Component
export default class ConfirmationMixin extends Vue {
  validateToken(confirmationToken: string) {
    const path = 'confirmations/validate';
    const param = queryParams({ confirmation_token: confirmationToken })
    return this.$httpGet(BASE_API_URL + path + '?' + param, 'confirmation')
  }

  confirmWithPassword(confirmationToken: string, password: string) {
    const path = 'confirmations/with_password';
    return this.$httpPost(BASE_API_URL + path, 'confirmation', { confirmationToken, password })
  }
}
































































































import { Component, Mixins } from "vue-property-decorator";
import PasswordsMixin from "@/mixins/http/PasswordsMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import WithErrors from "@/directives/WithErrors";

const RESET_TAG = "reset";

@Component({ components: { DisplayErrors }, directives: { WithErrors } })
export default class ResetPassword extends Mixins(PasswordsMixin) {
  private password = "";
  private confirmPassword = "";
  private passwordResetted = false;

  @RegisterHttp(RESET_TAG) http!: RequestData;

  get resetToken() {
    return this.$route.query.token as string;
  }

  get passwordValid() {
    return this.password.length >= 8 && this.confirmPassword === this.password;
  }

  get requestTag() {
    return RESET_TAG;
  }

  submit() {
    const data = {
      user: {
        reset_password_token: this.resetToken,
        password: this.password,
        password_confirmation: this.confirmPassword
      },
      blah: this.resetToken
    };
    this.resetPassword(data, this.requestTag).then(
      () => {
        this.passwordResetted = true;
      }
    );
  }
}

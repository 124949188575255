











































































import { Component, Mixins } from "vue-property-decorator";
import ConfirmationMixin from "../../mixins/http/ConfirmationMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";

@Component({ components: { DisplayErrors } })
export default class Confirmation extends Mixins(ConfirmationMixin) {
  private isValid = true;
  private password = "";
  private repeatPassword = "";

  @RegisterHttp("confirmation") readonly http!: RequestData;

  get confirmationToken() {
    return this.$route.query.token as string;
  }

  get error() {
    return (
      this.repeatPassword.length > 0 &&
      this.password.length > 0 &&
      this.password !== this.repeatPassword
    );
  }

  created() {
    this.validateToken(this.confirmationToken).then(
      resp => {
        this.isValid = true;
      },
      err => {
        if (err?.response?.status === 422) {
          this.isValid = false;
        }
      }
    );
  }

  submit() {
    this.confirmWithPassword(this.confirmationToken, this.password).then(
      resp => {
        this.$router.push({ name: "success" });
      }
    );
  }
}

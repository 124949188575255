




























































import { Component, Mixins } from "vue-property-decorator";
import PasswordsMixin from "@/mixins/http/PasswordsMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import WithErrors from "@/directives/WithErrors";

const FORGOT_TAG = "forgot";

@Component({ components: { DisplayErrors }, directives: { WithErrors } })
export default class ForgotPassword extends Mixins(PasswordsMixin) {
  private email = "";
  private emailSent = false;
  private error = false;

  @RegisterHttp(FORGOT_TAG) readonly http!: RequestData;

  get requestTag() {
    return FORGOT_TAG;
  }

  submit() {
    this.forgotPassword({ user: { email: this.email } }, FORGOT_TAG).then(
      resp => {
        this.emailSent = true;
      },
      err => {
        this.error = true;
      }
    );
  }
}

import { createDecorator } from 'vue-class-component';
import Vue from 'vue';
import { httpStore } from '@/store/typed'

export const NoCache = createDecorator((options, key) => {
  // component options should be passed to the callback
  // and update for the options object affect the component
  if (options) {
    if (options.computed) {
      (options.computed[key] as any).cache = false;
    }
  }
});


export const RegisterHttp = (tag: string) => {
  return createDecorator((options, key) => {
    const beforeCreate = function (this: Vue) {
      httpStore.dispatchRegister(tag);
    };

    if (options.beforeCreate) {
      const oldBeforeCreate = options.beforeCreate;
      options.beforeCreate = function () {
        oldBeforeCreate.bind(this)();
        beforeCreate.bind(this)();
      };
    } else {
      options.beforeCreate = beforeCreate;
    }
    if (!options.computed) {
      options.computed = {};
    }
    options.computed[key] = function mappedGetter() {
      return httpStore.status[tag];
    };
  });
};


import { Component, Vue } from 'vue-property-decorator';
import { BASE_API_URL } from '../../utils/Constants';

@Component
export default class PasswordsMixin extends Vue {

  forgotPassword(data: any, tag: string) {
    const path = 'password';
    return this.$httpPost(BASE_API_URL + path, tag, data);
  }
  resetPassword(data: any, tag: string) {
    const path = 'password';
    return this.$httpPut(BASE_API_URL + path, tag, data);
  }
}
